import { React, useState } from "react";
import Form from "./Form.js";
import { Copy, Experience } from "../helper/helper.js";
import Typewriter from "typewriter-effect";
import CountUp from "react-countup";
import Diploma from "../images/Toronto-Leshan Patterson DIPLOMA February-25-2022.pdf";
import googleCertificate from "..//images/Coursera 59FY983ERAEB.pdf";
import Certificate from "../images/AWS Machine Learning Certificate.pdf";
import { dataFunction } from "../helper/helper.js";

export default function Body(props) {
  let [projectTagStyle, setProjectTagStyle] = useState("");

  let headerMovingText = "I am a Full Stack Developer";

  let headerMovingTextFunction = (props) => {
    let res = "";
    for (let char of props) {
      res = res + char;
      document.getElementById("description").value = res;
    }
  };

  // headerMovingTextFunction(headerMovingText);

  let projectsObject = dataFunction().map((project, index) => {
    if (index <= 9) {
      return (
        <a href={project.project_link} target="_blank">
          <div
            className='project'
            key={index}
            style={{
              backgroundImage: `url(${project.project_image})`,
            }}>
            <div className='project__tag'>
              <p><strong>{project.project_name}</strong></p>
              <p>{project.project_description}</p>
              <p>Built with: {project.built_with}</p>
            </div>
          </div>
        </a>
      );
    }
  });

  return (
    <section className='body'>
      <div className='header'>
        <div className='body__header__text'>
          <h1>
            Hi, I am Leshan
            <Typewriter
              options={{ loop: true, autoStart: true }}
              onInit={(typewriter) => {
                typewriter
                  .typeString("I am a Full Stack Developer")
                  .pauseFor(3000)
                  .deleteAll()
                  .typeString("I develop responsive websites")
                  .pauseFor(3000)
                  .deleteAll()
                  .typeString("I build server-side functions")
                  .pauseFor(3000)
                  .deleteAll()
                  .typeString("I design and redesign databases")
                  .pauseFor(3000)
                  .deleteAll()
                  .typeString(". . .")
                  .pauseFor(3000)
                  .deleteAll()
                  .start();
              }}
            />
          </h1>
          <a href='#projects'>
            <button>Explore</button>
          </a>
        </div>
        <div className='body__header'></div>
      </div>
      <div className='body__experience' id='about'>
        <div>
          <label>
            <CountUp end={Experience()} />
          </label>
          <span>Years Experience</span>
        </div>
        <div>
          <label>
            {" "}
            <CountUp duration={2.5} end={50} />+
          </label>
          <span>Happy Clients</span>
        </div>
        <div>
          <label>
            <CountUp duration={2.5} end={50} />+
          </label>
          <span>Completed Projects</span>
        </div>
      </div>
      <div className='body__skills' id='experience'>
        <label>About Me</label>
        <div>
          <div>
            <label>Front End Development</label>
            <p>
              I develop responsive websites that produce the perfect user
              experience using a combination of designs, technology, and
              programming languages.
            </p>
          </div>
          <div>
            <label>Back End Development</label>
            <p>
              I build and maintain the mechanisms that process data and perform
              actions on websites including data storage, security, and other
              server-side functions.
            </p>
          </div>
          <div>
            <label>Database Development</label>
            <p>
              I design, program, construct and implement new databases, as well
              as modify existing databases for platform updates and changes in
              user needs.
            </p>
          </div>
        </div>
      </div>
      <div className='experience'>
        <div>
          <label>Work Experience</label>
          <div>
            <strong>spark* advocacy</strong> <span>April/2022 - Present</span>
            <p>Full Stack Developer</p>
            <ul>
              <li>
                <p>
                Developed and implemented responsive design improvements contributing to a 3x growth in website turnover.
                </p>
              </li>
              <li>
                <p>
                  Led website development projects, managing lifecycles from concept to launch, reducing costs by 25%.
                </p>
              </li>
              <li>
                <p>
                  Optimized CMS and static websites for performance, accessibility (WCAG AAA), and SEO.
                </p>
              </li>
              <li>
                <p>
                  Maintain sites and Implement and uphold a secure workflow to
                  avoid hacks to server infrastructure.
                </p>
              </li>
            </ul>
          </div>
          <div>
            <strong>CTRLALTDEL.IO</strong> <span>Jan/2021 - Nov/2021</span>
            <p>Front End Developer</p>
            <ul>
              <li>
                <p>
                  Optimized WordPress sites for performance, SEO, and conversions, achieving a 20% increase in performance.
                </p>
              </li>
              <li>
                <p>
                  Integrated complex APIs to enhance website functionality and user engagement.
                </p>
              </li>
              <li>
                <p>
                  Designed responsive, user-friendly websites using Figma, Adobe XD, and modern web standards.
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div>
          <label>Education</label>
          <div>
            <strong>Google UX Design</strong> <span>Dec/2022 - Jun/2023</span>
            <p>Professional Certificate</p>
            <ul>
              <li>
                <p>
                  Create designs on paper and in digital design tools like Figma
                  and Adobe XD
                </p>
              </li>
              {/* <li>
                {" "}
                <p>Software Architecture</p>
              </li>
              <li>
                <p>React Js</p>
              </li>
              <li>
                <p>Ruby on Rails</p>
              </li> */}
            </ul>
            <p>
              <a href={googleCertificate} target='_blank' rel='noreferrer'>
                Certificate >
              </a>
            </p>
          </div>
          <div>
            <strong>Lighthouse Labs</strong> <span>Nov/2021 - Feb/2022</span>
            <p>Diploma In Web Development</p>
            <ul>
              <li>
                <p>JavaScript/Node.js</p>
              </li>
              <li>
                {" "}
                <p>Software Architecture</p>
              </li>
              <li>
                <p>React Js</p>
              </li>
              <li>
                <p>Ruby on Rails</p>
              </li>
            </ul>
            <p>
              <a href={Diploma} target='_blank' rel='noreferrer'>
                Diploma >
              </a>
            </p>
          </div>
          <div>
            <strong>Udacity</strong> <span>July/2021 - Oct/2021</span>
            <p>AWS Machine Learning Scholarship</p>
            <ul>
              <li>
                <p>Supervised Learning</p>
              </li>
              <li>
                {" "}
                <p>Unsupervised Learning</p>
              </li>
              <li>
                <p>Reinforcement Learning</p>
              </li>
            </ul>
            <p>
              <a href={Certificate} target='_blank' rel='noreferrer'>
                Certificate >
              </a>
            </p>
          </div>
          <div>
            <strong>University of Waterloo</strong> <span>2013 - 2017</span>
            <p>Computer Science</p>
          </div>
        </div>
      </div>
      <div className='body_projects' id='projects'>
        <label>Featured Projects</label>
        <div className='body_projects__array'>{projectsObject}</div>
      </div>
      <div className='form' id='contact'>
        <label>Get In Touch</label>
        <div className='form-toparea'></div>
        <Form />
      </div>
      <footer>
        <div>{Copy()}</div>
        <div>
          Email:{" "}
          <a href='mailto:leshan@leshanpatterson.com'>
            leshan@leshanpatterson.com
          </a>
        </div>
      </footer>
    </section>
  );
}
