import profilepic from "../images/portfolio.png";
import video from "../images/ScreenShot2022-10-19.png";
import vediai from "../images/ScreenShot2022-11-24.png";
import tdotJerk from "../images/tdot-jerk.png";
import tdcafe from "../images/tdcafe.png";
import namastekitchen from "../images/namastekitchen.png";
import crowngatestore from "../images/crowngatestore.png";
import focascanada from "../images/focascanada.png";
import apisynthesizer from "../images/api-synthesizer.png";

export function dataFunction() {
  const data = [
    {
      project_name: "FOCAS Canada",
      project_image: focascanada,
      project_description:
        "Empowering and supporting newcomers, refugees, and immigrants in the underserved Oromo community from Ethiopia as they navigate life in Canada.",
      project_link: "https://focascanada.org/",
      built_with: "WordPress, PHP, SCSS, Advanced Custom Fields (ACF), Bootstrap",
    },
    {
      project_name: "Crowngate Business Enterprises",
      project_image: crowngatestore,
      project_description:
        "Crowngate Business Enterprises, located in Rumuomasi - Port Harcourt, is a large distribution company specializing in the exchange of fast-moving consumer goods.",
      project_link: "https://crowngatestore.com/",
      built_with: "WordPress, PHP, SCSS, Advanced Custom Fields (ACF), Bootstrap",
    },
    {
      project_name: "Namaste Tibetan Kitchen",
      project_image: namastekitchen,
      project_description:
        "Namaste Tibetan Kitchen is your destination for authentic Tibetan flavors in the heart of Parkdale, Toronto.",
      project_link: "https://www.namastekitchen.ca/",
      built_with: "WordPress, PHP, SCSS, Advanced Custom Fields (ACF), Bootstrap",
    },
    {
      project_name: "Tashi Delek Cafe",
      project_image: tdcafe,
      project_description:
        "Tashi Delek Cafe is a charming Nepalese and Tibetan restaurant located in the heart of Parkdale.",
      project_link: "https://tdcafe.com/",
      built_with: "WordPress, PHP, SCSS, Advanced Custom Fields (ACF), Bootstrap",
    },
    {
      project_name: "Tdot Jerk",
      project_image: tdotJerk,
      project_description:
        "Restaurant ordering website for Tdot Jerk located in Toronto, Canada.",
      project_link: "https://www.tdotjerk.ca",
      built_with: "WordPress, PHP, SCSS",
    },
    {
      project_name: "leshanpatterson.com",
      project_image: profilepic,
      project_description: "Personal portfolio site built using React.js.",
      project_link: "https://leshanpatterson.com/",
      built_with: "React.js, JavaScript, SCSS, JSX",
    },
    {
      project_name: "M&L API Synthesizer",
      project_image: apisynthesizer,
      project_description:
        "JSON Array of objects API Synthesizer.",
      project_link: "https://api-synthesizer.netlify.app/",
      built_with: "React, Bootstrap",
    },
    {
      project_name: "app.vediai",
      project_image: vediai,
      project_description:
        "Vediai video sharing application built using ASP.Net.",
      project_link: "https://appvediai.netlify.app/",
      built_with: "ASP.NET CORE, C#, React, SCSS",
    },
    {
      project_name: "vediai.com",
      project_image: video,
      project_description:
        "Vediai helps you strengthen your interview skills by connecting you to industry professionals.",
      project_link: "https://vediai.com/",
      built_with: "PHP, JavaScript, SCSS, Wordpress",
    },
    {
      project_name: "Plant Reminder",
      project_image:
        "https://github.com/lpattersonn/PlantReminder/raw/master/ClientApp/images/PlantReminder.jpg?raw=true",
      project_description:
        "Plant Reminder is a .Net Core (Web API) solution with a React front-end that enables a user to water plants remotely.",
      project_link: "https://github.com/lpattersonn/PlantReminder",
      built_with: "C#, .NET, React.js, JavaScript, CSS, HTML",
    },
    {
      project_name: "Small Basket",
      project_image:
        "https://github.com/lpattersonn/small_basket/raw/master/src/images/smallbasket_homepage.png?raw=true",
      project_description:
        "Smallbasket is an application that allows users to shop from, and support local grocery stores. Users can purchase their grocery items online and have thier orders delivered.",
      project_link: "https://github.com/lpattersonn/small_basket",
      built_with: "React.js, JavaScript, CSS, HTML",
    },
    {
      project_name: "Giphy",
      project_image:
        "https://github.com/lpattersonn/giphy/raw/master/public/giphy_home.png?raw=true",
      project_description:
        "Giphy is a single-page web application that allows users to retrieve gifs based on their search preferences while also being able to select the number of desired search results.",
      project_link: "https://github.com/lpattersonn/giphy",
      built_with: "React.js, JavaScript, CSS, HTML",
    },
    {
      project_name: "Student Profile",
      project_image:
        "https://github.com/lpattersonn/student_profile/raw/master/public/home.png?raw=true",
      project_description:
        "Student profile is a single page web application that allows user to see student information such as name, avatar, company, skill, average, in addition to allowing users to add tags to a specific profile.",
      project_link: "https://github.com/lpattersonn/student_profile",
      built_with: "React.js, JavaScript, CSS, HTML",
    },
    {
      project_name: "Pocket Lawyer",
      project_image:
        "https://github.com/lpattersonn/pocketlawyer/raw/main/public/homepage.png?raw=true",
      project_description:
        "Pocket Lawyer is a web application that allow users to connect to lawyers, in real time, and find legal information.",
      project_link: "https://github.com/lpattersonn/pocketlawyer",
      built_with: "React.js, JavaScript, CSS, HTML",
    },
    {
      project_name: "Quiz App",
      project_image:
        "https://camo.githubusercontent.com/df699889540753f97b011d3d052322c632f46a753322626c1902aacf2c092d17/68747470733a2f2f6d656469612e6973746f636b70686f746f2e636f6d2f766563746f72732f7175697a2d696e2d636f6d69632d706f702d6172742d7374796c652d7175697a2d627261696e792d67616d652d776f72642d766563746f722d696c6c757374726174696f6e2d766563746f722d6964313138363338363636383f6b3d3230266d3d3131383633383636363826733d3631327836313226773d3026683d7455767157586f4e61324f734a315973765a7772334a4b4f6767747a66725f4b48756f706967696e6450413d",
      project_description:
        "An app that lets you create quizzes and share them with friends. The creator of the quiz can view and share all the results at the end of the quiz.",
      project_link: "https://github.com/lpattersonn/quiz",
      built_with: "Node.js, JavaScript, CSS, HTML",
    },
  ];
  return data;
}

export function Age() {
  const birthday = 1995;
  const year = new Date().getFullYear();
  return year - birthday;
}

export function Copy() {
  let year = new Date().getFullYear();
  return `${year} © All Rights Reserved`;
}
export function Experience() {
  let startDate = 2021;
  let year = new Date().getFullYear();
  return year - startDate;
}
